import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Icon } from "@ryerson/frontend.assets";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { Video } from "@ryerson/frontend.media";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { renderBasicText } from "@components/Shared/model/Contentful";
import ContactUsForm from "@components/Shared/ContactUsRefactor/ContactUs";
import { SpecialForm } from "@components/Blog/SpecialForm";

export type BlogCategoryMin = {
	slug: string;
	title: string;
	categoryColor: string;
	ordinal: number;
	listPageImage: {
		file: {
			url: string;
		};
	};
};

export type BlogCategory = BlogCategoryMin & {
	blurb1: { blurb1: string };
	blurb2: { blurb2: string };
	calloutImage: {
		file: {
			url: string;
		};
	};
	heroImage: {
		file: {
			url: string;
		};
	};
	calloutText: {
		raw: any;
	};
	videoId: string;
	blog: any[];
	relatedCategories: BlogCategory[];
	seoTitle?: string;
	seoDescription?: string;
	verticalListPageImage: {
		file: {
			url: string;
		};
	};
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0px;
			}
		`;
	}}
`;

const RelativeContainer = styled.div`
	position: relative;
	display: block;
	width: 100%;
	height: auto;
`;

const ImageContainer = styled.div`
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	width: 560px;
	height: 360px;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const StaticContent: LanguageContent<any> = {
	en: {
		latestBlogs: "The Latest Blogs",
		loadMore: "Load More Posts",
		relatedCategories: "Want to learn more about metal? Check out these other categories:",
		allCategories: "All Content",
		learnMore: "Learn More",
	},
	fr: {
		latestBlogs: "Les derniers blogs",
		loadMore: "Charger plus de messages",
		relatedCategories:
			"Vous voulez en savoir plus sur le métal? Découvrez ces autres catégories:",
		allCategories: "Tout le contenu",
		learnMore: "En savoir plus",
	},
	es: {
		latestBlogs: "Los últimos blogs",
		loadMore: "Cargar más publicaciones",
		relatedCategories:
			"¿Quieres aprender más sobre el metal? Echa un vistazo a estas otras categorías:",
		allCategories: "Todo el contenido",
		learnMore: "Aprende más",
	},
};

const BlogCategoryPage: React.FC<LanguageContent<BlogCategory>> = (content) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const [slicePoint, setSlicePoint] = React.useState<number>(6);
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "50%",
							},
							{
								color: theme.colors.primary.background,
								percentage: "50%",
							},
						],
					}}
				>
					<RelativeContainer>
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb size="xs" type="tertiary" />
						</BreadcrumbContainer>
						<Typography
							variant="h1"
							type="tertiary"
							css={css`
								max-width: 460px;
								margin-top: 32px;
								margin-bottom: 24px;
								width: calc(100% - 560px);
							`}
						>
							{content[language].title}
						</Typography>
						<Typography
							variant="div"
							size="md"
							type="tertiary"
							css={css`
								max-width: 460px;
								margin-bottom: 96px;
								width: calc(100% - 560px);
							`}
						>
							{content[language].blurb1.blurb1}
						</Typography>
						<Typography
							variant="h3"
							type="primary"
							color={theme.colors.primary.text}
							css={css`
								max-width: 460px;
								width: calc(100% - 560px);
								margin-bottom: 48px;
							`}
						>
							{content[language].blurb2.blurb2}
						</Typography>
						<ImageContainer>
							{content &&
							content[language] &&
							content[language].videoId &&
							content[language].videoId.length > 0 ? (
								<Video
									videoId={content[language].videoId}
									buttonOrientation="left"
									imageUrl={content[language].heroImage.file.url}
								/>
							) : (
								<img
									src={content[language].heroImage.file.url}
									alt={content[language].title}
									css={css`
										display: block;
										object-fit: cover;
										width: 560px;
										height: 360px;
									`}
								/>
							)}
						</ImageContainer>
					</RelativeContainer>
				</ContentSection>
				<ContentSection type="secondary" vPadding="50px">
					<Flex direction="row" alignItems="center" justifyContent="space-between">
						<FlexItem
							css={css`
								width: calc(100% - 560px);
								max-width: 460px;
							`}
						>
							<img
								src={content[language].calloutImage.file.url}
								alt={content[language].title}
								css={css`
									object-fit: cover;
									width: 460px;
									height: 250px;
								`}
							/>
						</FlexItem>
						<FlexItem
							css={css`
								width: 560px;
								max-width: calc(100% - 460px);
							`}
						>
							{renderBasicText(content[language].calloutText.raw, "secondary")}
						</FlexItem>
					</Flex>
				</ContentSection>
				<ContentSection type="primary">
					<Typography
						variant="h2"
						css={css`
							margin-bottom: 60px;
						`}
					>
						{StaticContent[language].latestBlogs}
					</Typography>
					{content[language].blog &&
						content[language].blog.length > 0 &&
						content[language].blog
							.sort(
								(a: any, b: any) =>
									new Date(b.publishDate).valueOf() -
									new Date(a.publishDate).valueOf()
							)
							.slice(0, 3)
							.map((blog, index) => {
								return (
									<Link
										key={index}
										to={
											"/metal-resources/metal-market-intelligence/" +
											blog.slug
										}
									>
										<Flex
											key={index}
											justifyContent="space-between"
											css={css`
												margin-bottom: 40px;
											`}
										>
											<FlexItem
												css={css`
													width: 300px;
													height: 180px;
												`}
											>
												<img
													src={blog.heroImage.file.url}
													alt={blog.title}
													css={css`
														display: block;
														width: 300px;
														height: 180px;
														object-fit: cover;
													`}
												/>
											</FlexItem>
											<FlexItem
												css={css`
													width: calc(50% - 250px);
													height: 180px;
												`}
											>
												<Flex
													direction="column"
													css={css`
														height: 100%;
													`}
													justifyContent="space-between"
												>
													<FlexItem>
														<Typography variant="h3">
															{blog.title}
														</Typography>
													</FlexItem>
												</Flex>
											</FlexItem>
											<FlexItem
												css={css`
													width: calc(50% - 250px);
												`}
											>
												<Typography variant="div" type="primary" size="md">
													{blog.seoDescription}
												</Typography>
											</FlexItem>
										</Flex>
										<FlexItem>
											{blog.categories &&
												blog.categories.length > 0 &&
												blog.categories.map(
													(category: any, index: number) => {
														return (
															<div
																key={index}
																css={css`
																	display: inline-block;
																	margin-bottom: 40px;
																	margin-right: 10px;
																`}
															>
																<Badge
																	color={category.categoryColor}
																	type="primary"
																	key={index}
																>
																	{category.title}
																</Badge>
															</div>
														);
													}
												)}
										</FlexItem>
									</Link>
								);
							})}
				</ContentSection>

				<SpecialForm />
				<ContentSection type="primary" vPadding="50px">
					{content[language].blog &&
						content[language].blog.length > 0 &&
						content[language].blog
							.sort(
								(a: any, b: any) =>
									new Date(b.publishDate).valueOf() -
									new Date(a.publishDate).valueOf()
							)
							.slice(3, slicePoint)
							.map((blog, index) => {
								return (
									<Link
										key={index}
										to={
											"/metal-resources/metal-market-intelligence/" +
											blog.slug
										}
									>
										<Flex
											key={index}
											justifyContent="space-between"
											direction="row"
										>
											<FlexItem
												css={css`
													width: 300px;
													height: 180px;
												`}
											>
												<img
													src={blog.heroImage.file.url}
													alt={blog.title}
													css={css`
														display: block;
														width: 300px;
														height: 180px;
														object-fit: cover;
													`}
												/>
											</FlexItem>
											<FlexItem
												css={css`
													width: calc(50% - 250px);
													height: 180px;
												`}
											>
												<Flex
													direction="column"
													css={css`
														height: 100%;
														margin-right: 20px;
														margin-left: 20px;
													`}
													justifyContent="space-between"
												>
													<FlexItem>
														<Typography variant="h3">
															{blog.title}
														</Typography>
													</FlexItem>
												</Flex>
											</FlexItem>
											<FlexItem
												css={css`
													width: calc(50% - 250px);
												`}
											>
												<Typography variant="div" type="primary" size="md">
													{blog.seoDescription}
												</Typography>
											</FlexItem>
										</Flex>
										<FlexItem>
											{blog.categories &&
												blog.categories.length > 0 &&
												blog.categories.map(
													(category: any, index: number) => {
														return (
															<div
																key={index}
																css={css`
																	display: inline-block;
																	vertical-align: top;
																	margin-bottom: 40px;
																	padding-top: 30px;
																	margin-right: 10px;
																`}
															>
																<Badge
																	color={category.categoryColor}
																	type="primary"
																	key={index}
																>
																	{category.title}
																</Badge>
															</div>
														);
													}
												)}
										</FlexItem>
									</Link>
								);
							})}
					{content[language].blog &&
						content[language].blog.length > 6 &&
						slicePoint < content[language].blog.length - 1 && (
							<div
								css={css`
									width: 220px;
									height: 40px;
									line-height: 38px;
									border: 1px solid ${theme.colors.primary.gray};
									text-align: center;
									border-radius: 40px;
									cursor: pointer;
									display: block;
									margin: 0 auto 40px auto;
								`}
								onClick={() => {
									setSlicePoint(slicePoint + 3);
								}}
							>
								{StaticContent[language].loadMore}
							</div>
						)}
				</ContentSection>
				<ContactUsForm background="secondary" withImage={false} />
				<ContentSection type="primary" vPadding="50px">
					<Typography
						variant="h4"
						type="primary"
						css={css`
							margin-bottom: 40px;
						`}
					>
						{StaticContent[language].relatedCategories}
					</Typography>
					<Flex
						direction="row"
						justifyContent="center"
						css={css`
							gap: 20px;
						`}
						alignItems="center"
					>
						{content[language].relatedCategories.map((category, index) => {
							return (
								<Link
									key={index}
									to={
										"/metal-resources/metal-market-intelligence/" +
										category.slug
									}
								>
									<FlexItem
										css={css`
											height: 330px;
											width: 175px;
											position: relative;
											background-image: url(${category.verticalListPageImage
												.file.url});
											background-size: cover;
											background-repeat: no-repeat;
											background-position: center center;
											&:hover .learnMore {
												visibility: visible;
												opacity: 1;
											}
											&:hover .categoryTitle {
												bottom: 50px;
											}
										`}
									>
										<div
											css={css`
												background: linear-gradient(
													180deg,
													rgba(57, 67, 82, 0) 32.83%,
													rgba(57, 67, 82, 0.3889) 56.93%,
													#394352 81.02%
												);
												width: 100%;
												height: 100%;
											`}
										>
											<Typography
												variant="h4"
												type="tertiary"
												className="categoryTitle"
												css={css`
													position: absolute;
													bottom: 15px;
													left: 50%;
													transform: translateX(-50%);
													text-align: center;
													width: 140px;
													word-break: break-word;
													transition: bottom 0.15s linear;
												`}
											>
												{category.title}
											</Typography>
											<div
												className="learnMore"
												css={css`
													visibility: hidden;
													width: 100%;
													text-align: center;
													position: absolute;
													bottom: 15px;
													left: 50%;
													width: 140px;
													transform: translateX(-50%);
													opacity: 0;
													transition: visibility 0.15s,
														opacity 0.25s linear;
												`}
											>
												<div
													css={css`
														display: inline-block;
														width: 24px;
														height: 24px;
														border-radius: 24px;
														background-color: ${theme.colors.primary
															.primaryBrand};
														vertical-align: middle;
														line-height: 26px;
														text-align: center;
													`}
												>
													<Icon
														icon="chevron-right"
														size="xs"
														color={theme.colors.tertiary.header}
													/>
												</div>
												<Typography
													variant="div"
													size="md"
													type="tertiary"
													weight="bold"
													color={theme.colors.primary.white}
													css={css`
														display: inline-block;
														vertical-align: middle;
														margin-left: 8px;
													`}
												>
													{StaticContent[language].learnMore}
												</Typography>
											</div>
										</div>
									</FlexItem>
								</Link>
							);
						})}
						<Link to={"/metal-resources/metal-market-intelligence"}>
							<FlexItem
								css={css`
									height: 330px;
									width: 175px;
									position: relative;
									background-image: url(/images/metal-resources/metal-market-intelligence/AllBlogCategories.jpg);
									background-size: cover;
									background-repeat: no-repeat;
									background-position: center center;
									&:hover .learnMore {
										visibility: visible;
										opacity: 1;
									}
									&:hover .categoryTitle {
										bottom: 50px;
									}
								`}
							>
								<div
									css={css`
										background: linear-gradient(
											180deg,
											rgba(0, 156, 222, 0) 32.83%,
											rgba(0, 156, 222, 0.3889) 56.93%,
											#009cde 81.02%
										);
										width: 100%;
										height: 100%;
									`}
								>
									<Typography
										variant="h4"
										type="tertiary"
										className="categoryTitle"
										css={css`
											position: absolute;
											bottom: 15px;
											left: 50%;
											transform: translateX(-50%);
											text-align: center;
											width: 140px;
											word-break: break-word;
											transition: bottom 0.15s linear;
										`}
									>
										{StaticContent[language].allCategories}
									</Typography>
									<div
										className="learnMore"
										css={css`
											visibility: hidden;
											width: 100%;
											text-align: center;
											position: absolute;
											bottom: 15px;
											left: 50%;
											width: 140px;
											transform: translateX(-50%);
											opacity: 0;
											transition: visibility 0.15s, opacity 0.25s linear;
										`}
									>
										<div
											css={css`
												display: inline-block;
												width: 24px;
												height: 24px;
												border-radius: 24px;
												background-color: ${theme.colors.primary
													.secondaryBrand};
												vertical-align: middle;
												line-height: 26px;
												text-align: center;
											`}
										>
											<Icon
												icon="chevron-right"
												size="xs"
												color={theme.colors.tertiary.header}
											/>
										</div>
										<Typography
											variant="div"
											size="md"
											type="tertiary"
											weight="bold"
											color={theme.colors.primary.white}
											css={css`
												display: inline-block;
												vertical-align: middle;
												margin-left: 8px;
											`}
										>
											{StaticContent[language].learnMore}
										</Typography>
									</div>
								</div>
							</FlexItem>
						</Link>
					</Flex>
					<Spacer
						css={css`
							height: 40px;
						`}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "50%",
							},
							{
								color: theme.colors.primary.background,
								percentage: "50%",
							},
						],
					}}
				>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb size="xs" type="tertiary" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 18px;
							margin-bottom: 24px;
						`}
					>
						{content[language].title}
					</Typography>
					<Typography
						variant="div"
						size="md"
						type="tertiary"
						css={css`
							margin-bottom: 54px;
						`}
					>
						{content[language].blurb1.blurb1}
					</Typography>

					<ImageContainer
						css={css`
							display: block;
							position: static;
							top: unset;
							right: unset;
							transform: unset;
							width: 300px;
							height: 195px;
							margin: 0 auto;
						`}
					>
						{content[language].videoId && content[language].videoId.length > 0 ? (
							<Video
								videoId={content[language].videoId}
								buttonOrientation="top-right"
								imageUrl={content[language].heroImage.file.url}
							/>
						) : (
							<img
								src={content[language].heroImage.file.url}
								alt={content[language].title}
								css={css`
									display: block;
									object-fit: cover;
									width: 300px;
									height: 195px;
								`}
							/>
						)}
					</ImageContainer>
					<Typography
						variant="h3"
						type="primary"
						color={theme.colors.primary.text}
						css={css`
							margin-bottom: 32px;
							margin-top: 15px;
						`}
					>
						{content[language].blurb2.blurb2}
					</Typography>
				</ContentSection>
				<ContentSection type="secondary" vPadding="24px">
					<Flex
						direction="column"
						alignItems="center"
						justifyContent="flex-start"
						css={css`
							gap: 20px;
						`}
					>
						<FlexItem>
							<img
								src={content[language].calloutImage.file.url}
								alt={content[language].title}
								css={css`
									object-fit: cover;
									width: 100%;
									display: block;
									height: 200px;
								`}
							/>
						</FlexItem>
						<FlexItem
							css={css`
								width: 100%;
							`}
						>
							{renderBasicText(content[language].calloutText.raw, "secondary")}
						</FlexItem>
					</Flex>
				</ContentSection>
				<ContentSection type="primary">
					<Typography
						variant="h2"
						css={css`
							margin-bottom: 40px;
						`}
					>
						{StaticContent[language].latestBlogs}
					</Typography>
					{content[language].blog &&
						content[language].blog.length > 0 &&
						content[language].blog
							.sort(
								(a: any, b: any) =>
									new Date(b.publishDate).valueOf() -
									new Date(a.publishDate).valueOf()
							)
							.slice(0, 3)
							.map((blog, index) => {
								return (
									<Link
										key={index}
										to={
											"/metal-resources/metal-market-intelligence/" +
											blog.slug
										}
									>
										<Flex
											direction="column"
											justifyContent="flex-start"
											css={css`
												margin-bottom: 15px;
												gap: 10px;
											`}
										>
											<FlexItem
												css={css`
													width: 300px;
													height: 180px;
												`}
											>
												<img
													src={blog.heroImage.file.url}
													alt={blog.title}
													css={css`
														display: block;
														width: 100%;
														height: 180px;
														object-fit: cover;
													`}
												/>
											</FlexItem>

											<FlexItem>
												<Typography variant="h3">{blog.title}</Typography>
											</FlexItem>
											<FlexItem>
												{blog.categories &&
													blog.categories.length > 0 &&
													blog.categories.map(
														(category: any, index: number) => {
															return (
																<div
																	key={index}
																	css={css`
																		display: inline-block;
																		vertical-align: top;
																		margin-bottom: 5px;
																		margin-right: 5px;
																	`}
																>
																	<Badge
																		color={
																			category.categoryColor
																		}
																		type="primary"
																		key={index}
																	>
																		{category.title}
																	</Badge>
																</div>
															);
														}
													)}
											</FlexItem>
											<FlexItem>
												<Typography variant="div" type="primary" size="md">
													{blog.seoDescription}
												</Typography>
											</FlexItem>
										</Flex>
									</Link>
								);
							})}
				</ContentSection>

				<SpecialForm />
				<ContentSection type="primary" vPadding="34px">
					{content[language].blog &&
						content[language].blog.length > 0 &&
						content[language].blog
							.sort(
								(a: any, b: any) =>
									new Date(b.publishDate).valueOf() -
									new Date(a.publishDate).valueOf()
							)
							.slice(3, slicePoint)
							.map((blog, index) => {
								return (
									<Link
										key={index}
										to={
											"/metal-resources/metal-market-intelligence/" +
											blog.slug
										}
									>
										<Flex
											direction="column"
											justifyContent="flex-start"
											css={css`
												margin-bottom: 15px;
												gap: 10px;
											`}
										>
											<FlexItem
												css={css`
													width: 300px;
													height: 180px;
												`}
											>
												<img
													src={blog.heroImage.file.url}
													alt={blog.title}
													css={css`
														display: block;
														width: 100%;
														height: 180px;
														object-fit: cover;
													`}
												/>
											</FlexItem>

											<FlexItem>
												<Typography variant="h3">{blog.title}</Typography>
											</FlexItem>
											<FlexItem>
												{blog.categories &&
													blog.categories.length > 0 &&
													blog.categories.map(
														(category: any, index: number) => {
															return (
																<div
																	key={index}
																	css={css`
																		display: inline-block;
																		vertical-align: top;
																		margin-bottom: 5px;
																		margin-right: 5px;
																	`}
																>
																	<Badge
																		color={
																			category.categoryColor
																		}
																		type="primary"
																		key={index}
																	>
																		{category.title}
																	</Badge>
																</div>
															);
														}
													)}
											</FlexItem>
											<FlexItem>
												<Typography variant="div" type="primary" size="md">
													{blog.seoDescription}
												</Typography>
											</FlexItem>
										</Flex>
									</Link>
								);
							})}
					{content[language].blog &&
						content[language].blog.length > 6 &&
						slicePoint < content[language].blog.length - 1 && (
							<div
								css={css`
									width: 220px;
									height: 40px;
									line-height: 38px;
									border: 1px solid ${theme.colors.primary.gray};
									text-align: center;
									border-radius: 40px;
									cursor: pointer;
									display: block;
									margin: 0 auto 40px auto;
								`}
								onClick={() => {
									setSlicePoint(slicePoint + 3);
								}}
							>
								{StaticContent[language].loadMore}
							</div>
						)}
				</ContentSection>
				<ContactUsForm background="secondary" withImage={false} />
				<ContentSection type="primary" vPadding="24px">
					<Typography
						variant="h4"
						type="primary"
						css={css`
							margin-bottom: 40px;
						`}
					>
						{StaticContent[language].relatedCategories}
					</Typography>
					<Flex
						direction="row"
						justifyContent="center"
						css={css`
							gap: 20px;
							flex-wrap: wrap;
						`}
						alignItems="center"
					>
						{content[language].relatedCategories.map((category, index) => {
							return (
								<Link
									key={index}
									to={
										"/metal-resources/metal-market-intelligence/" +
										category.slug
									}
								>
									<FlexItem
										css={css`
											height: 250px;
											width: 140px;
											position: relative;
											background-image: url(${category.verticalListPageImage
												.file.url});
											background-size: cover;
											background-repeat: no-repeat;
											background-position: center center;
										`}
									>
										<div
											css={css`
												background: linear-gradient(
													180deg,
													rgba(57, 67, 82, 0) 32.83%,
													rgba(57, 67, 82, 0.3889) 56.93%,
													#394352 81.02%
												);
												width: 100%;
												height: 100%;
											`}
										>
											<Typography
												variant="h4"
												type="tertiary"
												css={css`
													position: absolute;
													bottom: 20px;
													left: 50%;
													transform: translateX(-50%);
													text-align: center;
													width: 120px;
													word-break: break-word;
												`}
											>
												{category.title}
												<div
													css={css`
														display: block;
														width: 100%;
														margin-top: 10px;
														text-align: center;
													`}
												>
													<div
														css={css`
															display: inline-block;
															width: 24px;
															height: 24px;
															border-radius: 24px;
															background-color: ${theme.colors.primary
																.primaryBrand};
															vertical-align: middle;
															line-height: 26px;
															text-align: center;
														`}
													>
														<Icon
															icon="chevron-right"
															size="xs"
															color={theme.colors.tertiary.header}
														/>
													</div>
													<Typography
														variant="div"
														size="md"
														type="tertiary"
														weight="bold"
														color={theme.colors.primary.white}
														css={css`
															display: inline-block;
															vertical-align: middle;
															margin-left: 5px;
														`}
													>
														{StaticContent[language].learnMore}
													</Typography>
												</div>
											</Typography>
										</div>
									</FlexItem>
								</Link>
							);
						})}
						<Link to={"/metal-resources/metal-market-intelligence"}>
							<FlexItem
								css={css`
									height: 250px;
									width: 140px;
									position: relative;
								`}
							>
								<img
									src="/images/metal-resources/metal-market-intelligence/AllBlogCategories.jpg"
									alt="All content"
									css={css`
										width: 140px;
										height: 250px;
										object-fit: cover;
									`}
								/>
								<Typography
									variant="h4"
									type="tertiary"
									css={css`
										position: absolute;
										bottom: 20px;
										left: 50%;
										transform: translateX(-50%);
										text-align: center;
										width: 120px;
										word-break: break-word;
									`}
								>
									{StaticContent[language].allCategories}
									<div
										css={css`
											display: block;
											width: 100%;
											margin-top: 10px;
											text-align: center;
										`}
									>
										<div
											css={css`
												display: inline-block;
												width: 24px;
												height: 24px;
												border-radius: 24px;
												background-color: ${theme.colors.primary
													.secondaryBrand};
												vertical-align: middle;
												line-height: 26px;
												text-align: center;
											`}
										>
											<Icon
												icon="chevron-right"
												size="xs"
												color={theme.colors.tertiary.header}
											/>
										</div>
										<Typography
											variant="div"
											size="md"
											type="tertiary"
											weight="bold"
											color={theme.colors.primary.white}
											css={css`
												display: inline-block;
												vertical-align: middle;
												margin-left: 5px;
											`}
										>
											{StaticContent[language].learnMore}
										</Typography>
									</div>
								</Typography>
							</FlexItem>
						</Link>
					</Flex>
					<Spacer
						css={css`
							height: 20px;
						`}
					/>
				</ContentSection>
			</Media>
		</>
	);
};

export default BlogCategoryPage;
